<template>
  <article>
    <div class="mb-6 flex items-center justify-between container">
      <button
        class="focus:outline-none group inline-flex items-center text-2xs font-bold uppercase"
        @click="back"
      >
        <IconChevronLeft
          class="mr-1 h-5 w-5 text-neutral-400 transition-all duration-200 group-hover:text-neutral-900"
        />
        {{ $t("global.backToOverview") }}
      </button>
      <BaseButton
        v-if="$can('write:menu') && dish"
        size="large"
        variant="outline"
        class="bg-white"
        :text="$t(`components.menus.editDish`)"
        @buttonClick="redirectToEditDish"
      >
        <template #iconLeft>
          <IconEdit class="text-neutral-300" />
        </template>
      </BaseButton>
    </div>
    <div class="sm:container">
      <div v-if="dishError">
        {{ dishError }}
      </div>
      <div v-else>
        <div class="grid grid-cols-1 gap-5 md:grid-cols-12 md:gap-8">
          <div
            class="border overflow-hidden border-neutral-50 bg-white sm:rounded-t-md sm:rounded-b-sm md:col-start-1 md:col-end-9"
          >
            <DishHeader :dish="dish" />
            <DishIngredients
              :ingredients="dish.ingredients"
              :comments="ingredientComments"
            />
            <DishTabs :dish="dish" />
          </div>
          <div class="md:col-start-9 md:col-end-13">
            <DishAllergens :allergens="dish.allergens" />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

import DishHeader from "@/components/menus/DishHeader";
import DishTabs from "@/components/menus/DishTabs";
import DishIngredients from "@/components/menus/DishIngredients";
import DishAllergens from "@/components/menus/DishAllergens";

export default {
  name: "DishDetail",
  components: {
    DishHeader,
    DishTabs,
    DishIngredients,
    DishAllergens,
  },
  beforeRouteEnter(to, _from, next) {
    store
      .dispatch("dish/fetchDish", { dishUuid: to.params.dishUuid })
      .then(() => next());
  },
  computed: {
    ...mapGetters("dish", ["dish", "dishError"]),

    ingredientComments() {
      return this.dish?.content?.comments;
    },
  },
  methods: {
    redirectToEditDish() {
      this.$router.push({
        name: "edit-dish",
        params: {
          dishUuid: this.dish.uuid,
        },
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
