<template>
  <header>
    <div class="relative bg-neutral-50 pt-1/2">
      <ResizedImage
        v-if="dish.coverImage"
        :src="dish.coverImage.url"
        :width="1280"
        :height="720"
        crop
        class="absolute top-0 left-0 h-full w-full object-cover"
      />
      <div
        v-else
        class="absolute top-0 left-0 flex h-full w-full items-center justify-center"
      >
        <IconBorettis class="h-32 w-32 text-neutral-300" />
      </div>
    </div>
    <div class="w-full flex-wrap justify-between p-5 py-8 md:p-12 xl:flex">
      <div class="mb-8 xl:pr-20">
        <h1 class="text-2xl font-medium" :class="{ 'mb-3': description }">
          {{ dish.name }}
        </h1>
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-if="description"
          class="text-sm text-neutral-600"
          v-html="description"
        ></p>
        <!-- eslint-enable vue/no-v-html -->
      </div>
      <div class="mt-2 grid grid-cols-2 gap-x-6 text-sm text-neutral-600">
        <ul class="grid content-start gap-4">
          <li v-if="cutleryName" class="flex">
            <IconCutlery class="mr-4 flex-shrink-0 text-neutral-300" />
            <div>{{ cutleryName }}</div>
          </li>
          <li class="flex">
            <IconUser class="mr-4 flex-shrink-0 text-neutral-300" />
            <div>{{ dish.servings }} {{ $t("components.menus.servings") }}</div>
          </li>
          <li v-if="dish.prepTime > 0" class="flex">
            <IconClock class="mr-4 flex-shrink-0 text-neutral-300" />
            <div>{{ dish.prepTime }} {{ $t("components.menus.min") }}</div>
          </li>
        </ul>
        <ul class="grid content-start gap-4">
          <li v-if="dish.costPrice && $can('read:costPrice')" class="flex">
            <IconCostprice class="mr-4 flex-shrink-0 text-neutral-300" />
            <div>{{ formatPrice(dish.costPrice) }}</div>
          </li>
          <li v-if="dish.salesPrice" class="flex">
            <IconSalesprice class="mr-4 flex-shrink-0 text-neutral-300" />
            <div>{{ formatPrice(dish.salesPrice) }}</div>
          </li>
          <li v-if="result && $can('read:dishResult')" class="flex">
            <IconLoss
              v-if="result < 0"
              class="mr-4 flex-shrink-0 text-neutral-300"
            />
            <IconProfit v-else class="mr-4 flex-shrink-0 text-neutral-300" />
            <div>{{ formatPrice(result) }}</div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DishHeader",

  props: {
    dish: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      dishCutlery: "dish/dishCutlery",
    }),

    cutleryName() {
      return this.dishCutlery.find(
        (cutlery) => cutlery.id === this.dish?.cutlery,
      )?.name;
    },

    description() {
      return this.dish.description
        .replace(/^\s+|\s+$/g, "")
        .replace(/\r?\n|\r/g, "<br>");
    },

    salesPriceExcludingVat() {
      if (!this.dish.salesPrice || !this.dish.taxRate) {
        return null;
      }

      return this.dish.salesPrice / (1 + this.dish.taxRate / 100);
    },

    result() {
      if (!this.salesPriceExcludingVat || !this.dish.costPrice) {
        return null;
      }

      return this.salesPriceExcludingVat - this.dish.costPrice;
    },
  },

  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
  },
};
</script>
