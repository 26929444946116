<template>
  <div class="border-t w-full border-neutral-50 p-5 py-8 md:p-12 md:pb-8">
    <table v-if="ingredients.length > 0" class="w-full">
      <tr>
        <td v-if="comments.length">
          <h3 class="mb-4 font-medium text-neutral-900">
            {{ $t("components.menus.dishPortions") }}
          </h3>
        </td>
        <td>
          <h3 class="mb-4 font-medium text-neutral-900">
            {{ $t("components.menus.dishIngredients") }}
          </h3>
        </td>
      </tr>
      <tr
        v-for="ingredient in mergedIngredients"
        :key="ingredient.index"
        class="text-sm text-neutral-600"
      >
        <td v-if="comments.length" class="py-2 pr-5 font-medium md:pr-8">
          {{ ingredient.comment }}
        </td>
        <td class="py-2">
          {{ `${ingredient.amount} ${ingredient.unit} ${ingredient.name}` }}
        </td>
      </tr>
    </table>
    <div v-else class="text-sm text-neutral-600">
      {{ $t("components.menus.dishNoIngredients") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DishIngredients",

  props: {
    ingredients: {
      type: Array,
      required: true,
    },
    comments: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    sortedIngredients() {
      return [...this.ingredients].sort((a, b) => a.sortIndex - b.sortIndex);
    },

    mergedIngredients() {
      return this.sortedIngredients.map((ingredient, index) => {
        const comment = this.comments[index];

        return {
          ...ingredient,
          comment: comment ? comment.text : null,
        };
      });
    },
  },
};
</script>
