<template>
  <div
    class="fixed top-0 left-0 z-50 flex h-full w-full items-end overflow-y-scroll sm:pt-8 md:flex md:items-center md:pt-16 lg:pt-0"
  >
    <div
      class="fixed top-0 left-0 h-full w-full bg-black opacity-25"
      @click="close"
    ></div>
    <div
      :class="[
        'relative mx-auto max-h-screen w-full',
        { 'max-w-xl': size === 'md' },
        { 'max-w-5xl': size === 'lg' },
      ]"
    >
      <div
        class="animation-default relative w-full overflow-hidden rounded-sm bg-white animation-modalSlide animation-bezier animation-once sm:animation-modalScale"
      >
        <button
          class="focus:outline-none absolute top-0 right-0 z-10 flex h-10 w-10 items-center justify-center bg-alpha-dark-10 text-white transition-all duration-200 hover:bg-alpha-dark-20 sm:h-8 sm:w-8"
          @click="close"
        >
          <IconClose />
        </button>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "md",
      validator: function (value) {
        return ["md", "lg"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    document.addEventListener("keyup", (evt) => {
      if (evt.keyCode === 27) {
        this.close();
      }
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
