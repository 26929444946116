<template>
  <a
    class="border group flex w-full w-full cursor-pointer items-center rounded-sm border-neutral-50 bg-neutral-0 p-3 transition-all duration-200 hover:border-neutral-100 md:p-4"
    @click="click"
  >
    <div
      class="border relative flex h-10 w-10 flex-shrink-0 items-center justify-center overflow-hidden rounded-full border-neutral-50 bg-primary-600 text-white"
    >
      <IconPlay class="h-5 w-5" />
    </div>
    <div class="flex-grow min-w-0 pl-4">
      <div class="flex w-full justify-between">
        <h4 class="truncate font-medium">{{ video.title }}</h4>
        <time class="ml-2 flex-shrink-0 text-sm text-neutral-600">
          {{ video.duration }}
        </time>
      </div>
      <div class="truncate text-xs text-neutral-400">
        {{ video.presenter }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
