var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dish.preparation.length || _vm.dish.assembly.length || _vm.dishDescription)?_c('div',[_c('nav',{staticClass:"border-b flex border-neutral-50 pl-5 text-neutral-400 md:pl-12"},[(_vm.dish.preparation.length)?_c('button',{class:[
        'border-b focus:outline-none transition-color mr-5 h-12 border-transparent text-sm duration-200 hover:text-neutral-900 md:mr-10',
        {
          'border-primary-500 text-neutral-900': _vm.activeTab === 'preparation',
        } ],on:{"click":function($event){_vm.activeTab = 'preparation'}}},[_vm._v(" "+_vm._s(_vm.$t("components.menus.dishPreparation"))+" ")]):_vm._e(),(_vm.dish.assembly.length)?_c('button',{class:[
        'border-b focus:outline-none transition-color mr-10 h-12 border-transparent text-sm duration-200 hover:text-neutral-900',
        {
          'border-primary-500 text-neutral-900': _vm.activeTab === 'assembly',
        } ],on:{"click":function($event){_vm.activeTab = 'assembly'}}},[_vm._v(" "+_vm._s(_vm.$t("components.menus.dishAssembly"))+" ")]):_vm._e(),(_vm.dishDescription)?_c('button',{class:[
        'border-b focus:outline-none transition-color mr-10 h-12 border-transparent text-sm duration-200 hover:text-neutral-900',
        {
          'border-primary-500 text-neutral-900': _vm.activeTab === 'description',
        } ],on:{"click":function($event){_vm.activeTab = 'description'}}},[_vm._v(" "+_vm._s(_vm.$t("components.menus.dishDescription"))+" ")]):_vm._e()]),_c('div',{staticClass:"p-5 py-8 md:p-12 md:pt-10"},[(_vm.activeTab === 'preparation' && _vm.dish.preparation.length)?_c('div',[_c('ol',{class:{ 'mb-8': _vm.dish.preparationVideo }},_vm._l((_vm.dish.preparation),function(preparationStep){return _c('li',{key:preparationStep.index,staticClass:"text-sm"},[_vm._v(" "+_vm._s(preparationStep.text)+" ")])}),0),(_vm.dish.preparationVideo)?_c('VideoCard',{attrs:{"video":_vm.dish.preparationVideo},on:{"click":_vm.openVideoModal}}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.videoModalVisible)?_c('Modal',{attrs:{"size":"lg"},on:{"close":_vm.close}},[_c('video',{staticClass:"h-auto max-h-portrait-video w-full bg-black",attrs:{"autoplay":"","controls":""}},[_c('source',{attrs:{"src":_vm.dish.preparationVideo.file.url,"type":"video/mp4"}})])]):_vm._e()],1)],1):_vm._e(),(_vm.activeTab === 'assembly' && _vm.dish.assembly.length)?_c('div',[_c('ol',_vm._l((_vm.dish.assembly),function(assemblyStep){return _c('li',{key:assemblyStep.index,staticClass:"text-sm"},[_vm._v(" "+_vm._s(assemblyStep.text)+" ")])}),0)]):_vm._e(),(_vm.activeTab === 'description' && _vm.dishDescription)?_c('div',[_c('div',{staticClass:"text-sm text-neutral-600",domProps:{"innerHTML":_vm._s(_vm.dishDescription)}})]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }