<template>
  <div
    v-if="dish.preparation.length || dish.assembly.length || dishDescription"
  >
    <nav class="border-b flex border-neutral-50 pl-5 text-neutral-400 md:pl-12">
      <button
        v-if="dish.preparation.length"
        :class="[
          'border-b focus:outline-none transition-color mr-5 h-12 border-transparent text-sm duration-200 hover:text-neutral-900 md:mr-10',
          {
            'border-primary-500 text-neutral-900': activeTab === 'preparation',
          },
        ]"
        @click="activeTab = 'preparation'"
      >
        {{ $t("components.menus.dishPreparation") }}
      </button>
      <button
        v-if="dish.assembly.length"
        :class="[
          'border-b focus:outline-none transition-color mr-10 h-12 border-transparent text-sm duration-200 hover:text-neutral-900',
          {
            'border-primary-500 text-neutral-900': activeTab === 'assembly',
          },
        ]"
        @click="activeTab = 'assembly'"
      >
        {{ $t("components.menus.dishAssembly") }}
      </button>
      <button
        v-if="dishDescription"
        :class="[
          'border-b focus:outline-none transition-color mr-10 h-12 border-transparent text-sm duration-200 hover:text-neutral-900',
          {
            'border-primary-500 text-neutral-900': activeTab === 'description',
          },
        ]"
        @click="activeTab = 'description'"
      >
        {{ $t("components.menus.dishDescription") }}
      </button>
    </nav>
    <div class="p-5 py-8 md:p-12 md:pt-10">
      <div v-if="activeTab === 'preparation' && dish.preparation.length">
        <ol :class="{ 'mb-8': dish.preparationVideo }">
          <li
            v-for="preparationStep in dish.preparation"
            :key="preparationStep.index"
            class="text-sm"
          >
            {{ preparationStep.text }}
          </li>
        </ol>
        <VideoCard
          v-if="dish.preparationVideo"
          :video="dish.preparationVideo"
          @click="openVideoModal"
        />
        <transition name="fade">
          <Modal v-if="videoModalVisible" size="lg" @close="close">
            <video
              class="h-auto max-h-portrait-video w-full bg-black"
              autoplay
              controls
            >
              <source :src="dish.preparationVideo.file.url" type="video/mp4" />
            </video>
          </Modal>
        </transition>
      </div>
      <div v-if="activeTab === 'assembly' && dish.assembly.length">
        <ol>
          <li
            v-for="assemblyStep in dish.assembly"
            :key="assemblyStep.index"
            class="text-sm"
          >
            {{ assemblyStep.text }}
          </li>
        </ol>
      </div>
      <div v-if="activeTab === 'description' && dishDescription">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="text-sm text-neutral-600" v-html="dishDescription"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoCard from "@/components/menus/VideoCard";
import Modal from "@/components/common/Modal";

export default {
  name: "DishHeader",

  components: {
    VideoCard,
    Modal,
  },

  props: {
    dish: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "preparation",
      video: {
        image: "https://source.unsplash.com/r01ZopTiEV8/800x600",
        title: "Whipped Ricotta cheese with herbs",
        description: "Preparation video  • by Ciro Di Marzio",
        duration: "6:14",
      },
      videoModalVisible: false,
    };
  },

  computed: {
    dishDescription() {
      return this.dish?.content?.description;
    },
  },

  mounted() {
    if (!this.dish.preparation.length && this.dish.assembly.length) {
      this.activeTab = "assembly";
    } else if (!this.dish.preparation.length && !this.dish.assembly.length) {
      this.activeTab = "description";
    }
  },

  methods: {
    openVideoModal() {
      this.videoModalVisible = true;
    },
    close() {
      this.videoModalVisible = false;
    },
  },
};
</script>

<style scoped>
ol li {
  counter-increment: step-counter;
  @apply mb-3 flex cursor-default text-sm-relaxed text-neutral-600 transition-all duration-200;
}

ol li:hover {
  @apply text-neutral-900;
}

ol li::before {
  content: counter(step-counter) ".";
  @apply mr-6 w-6 flex-shrink-0 font-medium text-neutral-900;
}
</style>
