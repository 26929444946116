<template>
  <div
    v-if="allergens.length"
    class="border rounded-sm border-neutral-50 bg-white px-5 py-8 md:p-8"
  >
    <h3 class="mb-4 font-medium text-neutral-900">
      {{ $t("components.menus.dishAllergens") }}
    </h3>
    <ul>
      <li
        v-for="allergen in allergens"
        :key="allergen.index"
        class="mb-2 text-sm text-neutral-600"
      >
        {{ allergen.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DishAllergens",

  props: {
    allergens: {
      type: Array,
      required: true,
    },
  },
};
</script>
